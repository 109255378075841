/* You can add global styles to this file, and also import other style files */
@import '../src/_variables.scss';

/* a theme is required to use any angular-material stuff... sigh. */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body.body {
  height: 100%;
  background-color: white;
}

body {
    //padding-top: 55px;
    height: 100%;
    overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
    margin: 0px; /*removes default style*/
    display: flex; /*enables flex content for its children*/
    box-sizing: border-box;
}
/**/
eil-root {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.stretch-height, eil-two-outlet-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.full-height {
    height: 100%;
}

#left {
    flex-shrink: 0; /*makes sure that content is not cut off in a smaller browser window*/
}

.top-left {
    flex-shrink: 0;
}

.top-right {
    flex-shrink: 0;
    display: inline-flex;
}

.bottom {
    flex-grow: 1; /*ensures that the container will take up the full height of the parent container*/
    overflow-y: auto; /*adds scroll to this container*/
}
/*This media query brought to you by "something is messing up scrolling and Grant doesn't have time to dick around with it" */
@media (max-width:768px) {
    .bottom {
        padding-bottom: 150px;
    }
}
/**/

.main-area {
  background-color: white;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 50px;
}

.gutter {
  /*position: fixed !important;
  top: 51px;
  bottom: 0;
  z-index: 1000;
  display: block;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;*/
  background-color: #f2f2f2;
}

.left-gutter {
  left: 0;
  border-width: 0 5px 0 0;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(rgba(0, 0, 0, 0)), to(gray)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0), gray) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0), gray) 1 100%;
  -o-border-image: -o-linear-gradient(right, rgba(0, 0, 0, 0), gray) 1 100%;
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0), gray) 1 100%;
}

.right-gutter {
  right: 0;
  border-width: 0 0 0 5px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(gray), to(rgba(0, 0, 0, 0))) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, gray, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, gray, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image: -o-linear-gradient(right, gray, rgba(0, 0, 0, 0)) 1 100%;
  border-image: linear-gradient(to right, gray, rgba(0, 0, 0, 0)) 1 100%;
}

.inline-icon-svg {
  height: 1em;
  fill: currentColor;
  width: 26px;
}

.page-form > div {
    padding: 35px $generousGutterWidth;
    margin-top: 30px;
}
@media (max-width: 1300px) {
    .page-form > div {
        padding: 35px 75px;
    }
}
@media (max-width: 1200px) {
    .page-form > div {
        padding: 35px 50px;
    }
}
@media (max-width: 768px) {
    .page-form > div {
        padding: 15px;
    }
}

.new-button-div {
  text-align: right;
  margin-right: 20px;
}

.lined-input-group input {
    /*border: 0;*/
    border-radius:0;

    font-size: 20px;
}

.expenses {
    .amount-input,
    .unit-cost-input,
    .single-amount-input,
    .other-description {
        /*background-color: #F4F4F4;*/
        font-weight: bold;
        font-size: 21px;
        color: black;

        &:disabled {
            background-color: transparent;
        }
    }

    .lined-input-group input[type='number'] {
        text-align: right;
    }
}
/*@media (max-width: 1010px) {
    .expenses {
        .amount-input,
        .unit-cost-input,
        .single-amount-input,
        .other-description {
            font-size: 20px;
        }
    }
}*/

.single-amount-input {
    width: 120px;
}

.anticipated-single-amount,
.anticipated-calc {
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    color: #219653;

    &.exceeded {
        color: #EB5757;
    }

    .amount-text,
    .total-text {
        font-weight: bold;
    }
}

.anticipated-text {
    display: inline-block;
    margin-right: 1em;
}

.form-control {
    border-radius:0 !important;
}

.form-control.form-control-plaintext:disabled,
.form-control.form-control-plaintext[readonly] {
    // the fact that the disabled/readonly style overrides the form-control-plaintext style seems
    // like a bug in bootstrap to me, as that doesn't seem like what it was intended for...
    background-color: transparent;
}

.modal-confirm-button-row {
    width:100%;
}

.red-no-border-button {
    background-color: transparent !important;
    color: red !important;
}


.header {
    padding-top: 10px;
    padding-bottom: 10px;
}



.app-list, .terms-list {
    margin: 20px;
    margin-top: 40px;
    font-size: 16px;
}

.list-table {
    width: 100%;

    .list-row {
        td {
            padding: 15px 0;
            border: 1px solid #eee;
            border-left: 0;
            border-right: 0;
        }
    }
}

.dropdown-menu.show {
    z-index: 1030;
}

a.dropdown-item {
    cursor: pointer;
}

.working {
    cursor: wait;
}

.lined-input-group {
    border-bottom: 1px solid #777;
    margin-bottom: 15px;
}

.navbar li {
    font-size: 20px;
    margin: 0 10px;

    a,
    a:hover,
    a:focus {
        border-bottom: 5px solid transparent;
        color: #7D7D7D;
    }

    a:hover {
        color: #F2994A;
    }
    /* Highlighting rules for nav menu items */
    &.link-active a,
    &.link-active a:hover,
    &.link-active a:focus {
        border-bottom: 5px solid #F2994A;
        color: #F2994A;
        text-decoration: none;
    }
}

.side-nav-item {
    margin: 20px 10px;
    font-size: 20px;

    a,
    a:hover,
    a:focus {
        border-bottom: 5px solid transparent;
        color: #7D7D7D;
        padding-bottom: 4px;
    }

    a:hover {
        color: #F2994A;
    }
    /* Highlighting rules for nav menu items */
    &.link-active a,
    &.link-active a:hover,
    &.link-active a:focus {
        color: #F2994A;
        border-bottom: 3px solid #F2994A;
        text-decoration: none;
    }
}

.smallnav {
    text-align: center;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    z-index: 1020;
    margin: 0px 10px;

    .side-nav-item {
        display: inline-block;
    }
}

@keyframes inlinespinner {
    to {
        transform: rotate(360deg);
    }
}

.inline-spinner {
    display:inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    border: 2px solid transparent;
    border-radius: 5px;
}

.inline-spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation: inlinespinner .6s linear infinite;
}

@media print {
    .gutter,
    .navbar {
        display: none !important;
    }
    .main-area {
        margin-left: 0 !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
    .side-nav-item {
        display: none !important;
    }

    .modal-backdrop.show {
        display: none !important;
    }

    .modal {
        /*position: initial !important;
        top: initial !important;
        bottom: initial !important;
        left: initial !important;
        right: initial !important;*/
        display: none !important;
    }

    .modal-open {
        overflow: auto !important;
    }

    html,
    body,
    eil-root,
    .stretch-height,
    .row.stretch-height,
    .row.full-height,
    eil-two-outlet-layout,
    .main-area {
        display: inline !important;
        overflow-y: auto !important;
    }

    .personal-details-help-col,
    .personal-details-editbtn-col,
    .event-details-help-block,
    .additional-event-info-help-block,
    .file-upload-add-button,
    .file-upload-trash-link,
    .file-selection,
    .file-uploading,
    .file-deleting,
    .submit-button-div {
        display: none;
    }
}

/* TEMP */
/*.navbar-wrapper .row > div {
    border: 1px solid red;
}*/
